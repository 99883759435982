import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store"; // Import your Vuex store
import PrimeVue from "primevue/config";
import Notifications from "@kyvg/vue3-notification";
import "./style.css";
import "@vueform/multiselect/themes/default.css";
import "./assets/sass/_index.sass";
import axios from "axios";
import state from "./store/state";
axios.defaults.baseURL = state.serverBaseUrl + "/api";
axios.defaults.headers.common["Content-Type"] = "Application/json";
axios.defaults.headers.common.Accept = "Application/json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
const app = createApp(App);
app.mixin({
  methods: {
    $scrollToTop() {
      const elements = document.querySelectorAll("div");
      elements.forEach((element) => {
        element.scrollTop = 0;
      });
    },
    $check_leaves_waiting_status() {
      // eslint-disable-next-line no-unused-vars
      let c_wait = 0;

      axios
        .create()
        .get("/vacations")
        .then((res) => {
          this.Lieaves = res.data.data.reverse();
          this.previousLieaves = res.data.data;
          this.Lieaves.forEach((leave) => {
            if (leave.status == "waiting") c_wait++;
          });
          store.commit("count_of_leaves_plus", c_wait);
          if (process.env.NODE_ENV === "development") this.LoadLeaves = false;
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            this.$notify({ title: err.response.data.message, type: "error" });
            // Handle the 401 error here
            // For example, you can redirect the user to a login page or show an error message
            console.log("Unauthorized! Redirecting to login page...");
            localStorage.clear();
            window.location.reload();
          } else {
            if (process.env.NODE_ENV === "development") console.log(err);
          }
        });
    },
  },
});
app.use(store); // Use the Vuex store
app.use(router);
app.use(Notifications);
app.use(PrimeVue);
app.mount("#app");
