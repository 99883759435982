const { mapState } = require("vuex");
module.exports = {
  computed: {
    ...mapState({
      count: (state) => state.count,
      isMini: (state) => (state.isMini = true),

      // methods...
      toggleMini(state) {
        return (state.isMini = !state.isMini);
      },
      isMiniTrue(state) {
        return (state.isMini = true);
      },
      isMiniFalse(state) {
        return (state.isMini = false);
      },
    }),
  },

  baseUrl: "http://localhost:8080/",
  // baseUrl: "https://office.vestatest.ir",

  // For Testing
  // serverBaseUrl: "https://office.vestatest.ir",

  // For Deploy
  serverBaseUrl: "https://office.vestasanat.com",
};
