<template>
  <div id="route-app">
    <notifications />
    <router-view> </router-view>
  </div>
</template>

<script>
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
export default {
  name: "App",
  mounted() {
    if (process.env.NODE_ENV !== "development")
    console.log(
  "The display of logs is hidden for you, to display the logs and run the project in development mode, run the following command in the project folder: " +
    "%cnpm run serve",
  "color: yellow"
);

  },
};
</script>

<style>
* {
  font-family: Iran-Sans;
  transition: all 200ms ease-in-out;
}
#route-app {
  background-image: url("@/assets/images/start/BackG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @apply bg-[#1D4062] w-screen h-screen fixed
    overflow-y-auto text-white select-none;
}
.overflow-y-auto::-webkit-scrollbar {
  display: none;
}
.vpd-icon-btn {
  @apply hidden !important;
}

/* vue form multiselect */
.multiselect {
  min-height: 0px !important;
}
.multiselect-wrapper {
  min-height: 0px !important;
}
.multiselect-dropdown {
  min-height: 0px;
  @apply text-xs;
}
.multiselect-option {
  @apply text-[12px] my-1 mx-2 rounded !important;
}
.multiselect-option:hover {
  @apply bg-[#474A5CCC] text-white !important;
}
.is-pointed {
  @apply bg-[#474A5CCC] text-white !important;
}

/* prime vue */
.p-dropdown .p-dropdown-label.p-placeholder {
  direction: rtl !important;
  @apply py-1.5 pr-4 text-sm !important;
  font-family: Iran-Sans !important;
}
.p-dropdown .p-dropdown-label {
  direction: rtl !important;
  @apply p-[4px] pr-4 !important;
  font-family: Iran-Sans !important;
}
.p-dropdown .p-dropdown-clear-icon {
  @apply float-left relative right-0 left-4 block !important;
}
</style>
