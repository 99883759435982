import { createStore } from "vuex";

export default createStore({
  state: {
    isMini: true,
    showSubM: false,
    count_of_leaves: 0,
  },
  mutations: {
    count_of_leaves_plus(state, newValue) {
      state.count_of_leaves = newValue;
    },
    isMiniFalse(state, newValue) {
      state.isMini = newValue;
    },
    toggleMini(state) {
      state.isMini = !state.isMini;
    },
    toggleSubM(state, newValue) {
      state.showSubM = newValue;
    },
  },
  actions: {
    // Define your action functions here
  },
  getters: {
    // Define your getter functions here
  },
});
