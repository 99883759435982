import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "login-page",
    component: () => import("./components/Pages/login-page/LoginPage.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard-page",
    component: () => import("./components/Dashboard/dashboard.vue"),
    children: [
      {
        path: "employee",
        name: "employee-page",

        component: () =>
          import("./components/Pages/employee-page/Theemployee.vue"),
      },
      {
        path: "users",
        name: "users-page",

        component: () => import("./components/Pages/users-page/TheUsers.vue"),
      },
      {
        path: "activity",
        name: "activity-page",
        component: () =>
          import("./components/Pages/activity/activity-panel.vue"),
        children: [
          {
            path: "arrivals-departures",
            name: "arrivals-departures",
            component: () =>
              import(
                "@/components/Pages/users-reports/arrivals-departures.vue"
              ),
          },
          {
            path: "employee-activities",
            name: "employee-activities",
            component: () =>
              import(
                "./components/Pages/users-reports/employee-activities.vue"
              ),
          },
          {
            path: "my-arrivals",
            name: "my-arrivals",
            component: () =>
              import("@/components/Pages/users-reports/my-arrivals.vue"),
          },
          {
            path: "my-activities",
            name: "my-activities",
            component: () =>
              import("@/components/Pages/activity/activity-page.vue"),
          },
        ],
      },
      {
        path: "projects",
        name: "projects-page",
        component: () =>
          import("./components/Pages/project-pages/projects-list.vue"),
      },
      // {
      //   path: "sales-pages",
      //   name: "sales-pages",
      //   children: [
      //     {
      //       path: "sales-invoices",
      //       name: "sales-invoices",
      //       component: () =>
      //         import("@/components/Pages/sales-invoices/sales-invoices.vue"),
      //     },
      //     {
      //       path: "return-invoices",
      //       name: "return-invoices",
      //       component: () =>
      //         import("@/components/Pages/sales-invoices/return-invoices.vue"),
      //     },
      //     {
      //       path: "purchase-order",
      //       name: "purchase-order",
      //       component: () =>
      //         import("@/components/Pages/sales-invoices/purchase-order.vue"),
      //     },
      //   ],
      // },
      // {
      //   path: "purchase-order",
      //   name: "purchase-pages",
      //   children: [
      //     {
      //       path: "accounting-purchase",
      //       name: "accounting-purchase",
      //       component: () =>
      //         import(
      //           "@/components/Pages/purchase-pages/accounting-purchase.vue"
      //         ),
      //     },
      //     {
      //       path: "all-return-purchase-invoices",
      //       name: "all-return-purchase-invoices",
      //       component: () =>
      //         import(
      //           "@/components/Pages/purchase-pages/all-return-purchase-invoices.vue"
      //         ),
      //     },
      //   ],
      // },
      {
        path: "sick-leave",
        name: "sick-leave",
        component: () =>
          import("./components/Pages/vacations-panel/vacation-panel.vue"),
        children: [
          {
            path: "request-leave",
            name: "request-leave",
            component: () =>
              import("./components/Pages/vacations-panel/request-leave.vue"),
          },
          {
            path: "leave-lists",
            name: "leave-lists",
            component: () =>
              import("./components/Pages/vacations-panel/leave-lists.vue"),
          },
          {
            path: "manage-leaves",
            name: "manage-leaves",
            component: () =>
              import("./components/Pages/vacations-panel/manage-leaves.vue"),
          },
        ],
      },

      {
        path: "settings-page",
        name: "settings-page",
        component: () =>
          import("./components/Pages/settings-panel/settings-page.vue"),
        children: [
          {
            path: "positions",
            name: "positions-page",
            component: () =>
              import("./components/Pages/settings-panel/positions.vue"),
          },
          {
            path: "department",
            name: "department-page",
            component: () =>
              import("./components/Pages/settings-panel/department.vue"),
          },
        ],
      },

      // {
      //   path: "bill",
      //   name: "bill",
      //   children: [
      //     {
      //       path: "add-pay-slip",
      //       name: "add-pay-slip",
      //       component: () =>
      //         import("@/components/Pages/pay-slip/addPaySlip.vue"),
      //     },
      //     {
      //       path: "pay-slip",
      //       name: "pay-slip",
      //       component: () => import("@/components/Pages/pay-slip/paySlip.vue"),
      //     },
      //     {
      //       path: "adding-fixed-constant",
      //       name: "adding-fixed-constant",
      //       component: () =>
      //         import("@/components/Pages/pay-slip/adding-fixed-constant.vue"),
      //     },
      //   ],
      // },

      // {
      //   path: "host",
      //   name: "host",
      //   children: [
      //     {
      //       path: "add-host",
      //       name: "add-host",
      //       component: () =>
      //         import("@/components/Pages/host-page/add-host.vue"),
      //     },
      //   ],
      // },
      // {
      //   path: "domain",
      //   name: "domain",
      //   children: [
      //     {
      //       path: "add-domain",
      //       name: "add-domain",
      //       component: () =>
      //         import("@/components/Pages/domain-page/add-domain.vue"),
      //     },
      //   ],
      // },
    ],
  },

  {
    path: "/register",
    name: "register-page",
    component: () =>
      import("./components/Pages/register-page/RegisterPage.vue"),
  },
  {
    path: "/password-recovery",
    name: "password-recovery",
    component: () =>
      import("./components/Pages/login-page/password-recovery.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "notfound-page",
    component: () => import("./components/layouts/404-page.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("token"); // Check if the user is authenticated

  // Check if the route requires authentication
  if (isAuthenticated === null) {
    // If the user is not authenticated and not already on the login page
    if (to.path !== "/") {
      // Redirect to the login page
      next("/");
    } else {
      // Allow navigation to continue
      next();
    }
  } else {
    // User is authenticated, allow navigation
    if (
      to.fullPath === "/dashboard/activity" ||
      to.fullPath === "/dashboard/sick-leave" ||
      to.fullPath === "/dashboard/settings-page"
    ) {
      // If the route is one of the restricted pages, prevent navigation
      next(false);
    } else {
      // Otherwise, allow navigation
      next();
    }
  }
});

export default router;
